import { FunctionComponent } from "react";

export type Section3Type = {
  className?: string;
};

const Section3: FunctionComponent<Section3Type> = ({ className = "" }) => {
  return (
    <section
      className={`
        w-full bg-whitesmoke overflow-hidden
        flex flex-col-reverse lg:flex-row items-center justify-between
        gap-8 px-6 py-12
        ${className}
      `}
    >
      {/* LEFT BLOCK */}
      <div className="flex-1 flex items-center justify-center lg:justify-end">
        <img
          src="/landing/section-3.svg"
          alt="Visual"
          className="w-full max-w-md lg:max-w-lg h-auto object-cover"
        />
      </div>

      {/* RIGHT BLOCK */}
      <div
        className={`
          flex-1 flex flex-col justify-center
          py-[68.5px] px-[50px] box-border gap-5
          min-w-[416px] max-w-full
          lg:items-start lg:text-left
          items-center text-center
          mq450:py-[29px]
        `}
      >
        {/* Title */}
        <div className="w-full max-w-[567px] text-xl text-cornflowerblue font-medium">
          AI-Powered Study Tool
        </div>

        {/* Heading */}
        <h1
          className="
            m-0 w-full max-w-[639px] text-41xl font-semibold leading-tight
            lg:text-left text-center
            mq450:text-29xl
          "
        >
          Customized Learning at Its Finest!
        </h1>

        {/* Features */}
        <div
          className={`
            flex flex-col gap-4
            w-full max-w-[500px]
            lg:items-start items-
          `}
        >
          {/* Feature 1 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">AI Generated Notes</span>
              <span className="text-base">
                Automatically produces clear, concise, and comprehensive notes
                to encapsulate the key information for effective memorization.
              </span>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Quizzes</span>
              <span className="text-base">
                Offers interactive assessments that test your understanding and
                reinforce memory retention of the material.
              </span>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Flashcards</span>
              <span className="text-base">
                Creates dynamic flashcards that facilitate quick review and help
                solidify key concepts in your memory.
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
