import { FunctionComponent } from "react";

export type Section4Type = {
  className?: string;
};

const Section4: FunctionComponent<Section4Type> = ({ className = "" }) => {
  return (
    <section
      className={`
        self-stretch bg-whitesmoke overflow-hidden
        flex flex-col lg:flex-row items-center justify-center
        gap-8 px-6 py-12
        max-w-full text-left text-xl text-black font-inter
        ${className}
      `}
    >
      {/* LEFT BLOCK */}
      <div
        className={`
          flex-1 flex flex-col justify-center
          py-[68.5px] px-[50px] box-border gap-5
          max-w-[640px]
          lg:items-start lg:text-left
          items-center text-center
          mq450:py-[29px]
        `}
      >
        <div className="w-full text-xl text-cornflowerblue font-medium">
          Secure Cloud Storage
        </div>
        <h1
          className="
            m-0 w-full max-w-[639px] text-41xl font-semibold leading-tight
            mq450:text-29xl
          "
        >
          Access Anytime, Anywhere
        </h1>

        {/* Features positioned below the title */}
        <div
          className={`
            flex flex-col gap-4
            w-full max-w-[500px]
            lg:items-start items-center
          `}
        >
          {/* Feature 1 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Cross-device Sync</span>
              <span className="text-base">
                Seamlessly sync your content across all your devices for a
                consistent experience.
              </span>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Cloud Backup</span>
              <span className="text-base">
                Securely store your data in the cloud with reliable backup and
                instant recovery.
              </span>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">
                Multiple Export Features
              </span>
              <span className="text-base">
                Easily export your content in multiple formats including audio,
                PDF, text, DOC, and Markdown.
              </span>
            </div>
          </div>
          {/* Feature 4 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Offline Audio Model</span>
              <span className="text-base">
                Leverage an offline transcription model to process and convert
                audio without an internet connection.
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* RIGHT BLOCK (IMAGE) */}
      <div
        className="
          flex-1 flex items-center justify-start
          max-w-[545px]
        "
      >
        <img
          className="
            w-full max-w-full h-auto object-cover
          "
          loading="lazy"
          alt="AI Boosted Visual"
          src="/landing/section-4.svg"
        />
      </div>
    </section>
  );
};

export default Section4;
