import React from "react";
import Footer from "../components/landing/Footer";
import TopNavBar from "../components/nav/TopNavBar";

const DataDeletion: React.FC = () => {
  return (
    <>
      <TopNavBar />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal] p-6">
        <h1 className="text-2xl font-bold mb-4">Account & Data Deletion</h1>
        <p className="mb-4">Last updated: 2025/02/25</p>

        <h2 className="text-xl font-semibold mb-2">1. Account Deletion</h2>
        <p className="mb-4">
          You can permanently delete your account and all associated data by:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>Going to your Profile settings</li>
          <li>Selecting "Delete Account"</li>
          <li>Confirming your choice</li>
        </ul>
        <p className="mb-4 text-red-600">
          Warning: This action is irreversible. All your data including notes,
          folders, AI conversations, and account information will be permanently
          deleted and cannot be recovered.
        </p>

        <h2 className="text-xl font-semibold mb-2">2. Data Retention Policy</h2>
        <p className="mb-4">
          When you delete content through the app, different retention rules
          apply:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>
            <strong>Recoverable for 60 days:</strong>
            <ul className="list-circle pl-5 mt-2">
              <li>Notes</li>
              <li>AI Conversations</li>
              <li>Generated Content</li>
              <li>Prompts</li>
            </ul>
          </li>
          <li className="mt-2">
            <strong>Immediately permanent deletion:</strong>
            <ul className="list-circle pl-5 mt-2">
              <li>Folders and organizational structure</li>
              <li>Account information</li>
              <li>Payment records</li>
            </ul>
          </li>
        </ul>
        <p className="mb-4">To recover deletable content within 60 days:</p>
        <ul className="list-disc pl-5 mb-4">
          <li>Go to Profile settings</li>
          <li>Select "Deleted Notes"</li>
          <li>Choose content to restore</li>
        </ul>
        <p className="text-red-600">
          After 60 days, all recoverable content will be permanently deleted
          from our servers.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default DataDeletion;
