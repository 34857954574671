import { FunctionComponent, useState } from "react";

export type IntroType = {
  className?: string;
};

const Intro: FunctionComponent<IntroType> = ({ className = "" }) => {
  const [showComingSoon, setShowComingSoon] = useState(false);

  return (
    <section
      className={`
        relative
        w-full
        bg-deepskyblue
        overflow-hidden
        ${className}
      `}
    >
      {/* Background image pinned top-left */}
      <img
        src="/landing/rectangle-69@2x.png"
        alt="Background"
        className="
          absolute
          top-0
          left-0
          h-auto
          max-w-none
          object-cover
        "
      />

      {/* Main content container using two equal columns */}
      <div
        className="
          relative
          z-[1]
          mx-auto
          px-6
          py-12
          flex
          flex-col
          md:flex-row
          items-center
          justify-between
          gap-8
          max-w-7xl
        "
      >
        {/* Text & Buttons container */}
        <div className="w-full md:w-1/2 pr-6 flex flex-col items-center md:items-start text-white text-center md:text-left">
          <h1
            className="
              text-41xl
              font-semibold
              leading-tight
              mb-6
            "
          >
            Unlock Your Potential: AI-Powered All-in-One Notes Assistant
          </h1>
          <p className="text-3xl">
            YouwoAI is your all-in-one AI assistant for smarter studying and
            efficient work. It helps you capture, organize, and review
            information effortlessly, making learning and productivity seamless.
            Whether you're a student or a professional, YouWoAi streamlines your
            workflow with AI-driven insights. Stay ahead with a smarter way to
            manage your notes.
          </p>
          {/* Download Images fixed below the text */}
          <div className="mt-6 flex flex-col sm:flex-row gap-4">
            <a
              href="https://apps.apple.com/cn/app/youwoai/id6504479938"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/landing/downloadapplewhite.svg"
                alt="Download on the App Store"
                className="h-12"
              />
            </a>
            {showComingSoon ? (
              <div className="w-48 h-12 flex items-center justify-center border-2 border-black bg-white text-black px-6 py-3 rounded-lg font-semibold shadow-md">
                Coming Soon
              </div>
            ) : (
              <a
                onClick={() => setShowComingSoon(true)}
                className="focus:outline-none cursor-pointer"
              >
                <img
                  src="/landing/googledownload.svg"
                  alt="Get it on Google Play"
                  className="h-12"
                />
              </a>
            )}
          </div>
        </div>

        {/* Image area */}
        <div className="w-full md:w-1/2 pl-6 flex items-center justify-center">
          <img
            src="/landing/intro.svg"
            alt="App Preview"
            className="max-w-full h-auto object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default Intro;
