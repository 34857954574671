import { FunctionComponent } from "react";
import Feature from "../components/landing/Features";
import Footer from "../components/landing/Footer";
import Intro from "../components/landing/Intro";
import Section1 from "../components/landing/Section1";
import Section2 from "../components/landing/Section2";
import Section3 from "../components/landing/Section3";
import Section4 from "../components/landing/Section4";
import Section5 from "../components/landing/Section5";
import TopNavBar from "../components/nav/TopNavBar";

const LandingPage: FunctionComponent = () => {
  return (
    <>
      <TopNavBar />
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
        <Intro />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Feature />
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
