import { FunctionComponent } from "react";

export type Section1Type = {
  className?: string;
};

const Section1: FunctionComponent<Section1Type> = ({ className = "" }) => {
  return (
    <section
      className={`
        w-full bg-whitesmoke overflow-hidden
        flex flex-col-reverse lg:flex-row items-center justify-between
        gap-8 px-6 py-12
        ${className}
      `}
    >
      {/* LEFT BLOCK */}
      <div className="flex-1 flex items-center justify-center lg:justify-end">
        <img
          src="/landing/section-1.svg"
          alt="Visual"
          className="w-full max-w-md lg:max-w-lg h-auto object-cover"
        />
      </div>

      {/* RIGHT BLOCK */}
      <div
        className={`
          flex-1 flex flex-col justify-center
          py-[68.5px] px-[50px] box-border gap-5
          min-w-[416px] max-w-full
          lg:items-start lg:text-left
          items-center text-center
          mq450:py-[29px]
        `}
      >
        {/* Title */}
        <div className="w-full max-w-[567px] text-xl text-cornflowerblue font-medium">
          Audio Booster
        </div>

        {/* Heading */}
        <h1
          className="
            m-0 w-full max-w-[639px] text-41xl font-semibold leading-tight
            lg:text-left text-center
            mq450:text-29xl
          "
        >
          Capture Every Word, Equation, and Insight
        </h1>

        {/* Features */}
        <div
          className={`
            flex flex-col gap-4
            w-full max-w-[500px]
            lg:items-start items-
          `}
        >
          {/* Feature 1 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Real-time Transcription</span>
              <span className="text-base">
                Capture every word instantly and accurately during recordings.
              </span>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Multilanguage Support</span>
              <span className="text-base">
                Transcribe speech in various languages seamlessly.
              </span>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Real-time Translation </span>
              <span className="text-base">
                Instantly translate your recording into another language as you
                speak, enabling seamless multilingual communication.
              </span>
            </div>
          </div>
          {/* Feature 5 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">
                Import from Multiple Sources
              </span>
              <span className="text-base">
                Easily import content from audio, video, documents, and image
                files.
              </span>
            </div>
          </div>

          {/* Feature 6 */}
          <div className="flex items-start gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover mt-1"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold">Clear Audio Recording</span>
              <span className="text-base">
                Ensure high-quality, crisp recordings for flawless
                transcription.
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
