import React from "react";

export type FeatureItem = {
  title: string;
  text: string;
  icon: string;
};

const features: FeatureItem[] = [
  {
    title: "AI Summarization",
    text: "Transforms complex lectures and study material into digestible, tailored summaries that match your unique learning needs.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Real-time Transcription",
    text: "Capture every word instantly and accurately during recordings.",
    icon: "/landing/icon.svg",
  },
  {
    title: "AI Conversation",
    text: "Engages in interactive dialogue to help generate creative ideas and deepen your understanding of topics.",
    icon: "/landing/icon.svg",
  },
  {
    title: "AI Generated Notes",
    text: "Automatically produces clear, concise, and comprehensive notes to encapsulate the key information for effective memorization.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Quizzes",
    text: "Offers interactive assessments that test your understanding and reinforce memory retention of the material.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Flashcards",
    text: "Creates dynamic flashcards that facilitate quick review and help solidify key concepts in your memory.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Folder Management",
    text: "Keep your notes organized and clutter-free with easy folder categorization.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Customizable Prompts",
    text: "Adapts to your personal study habits with prompts and insights designed to enhance and personalize your educational experience.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Clear Audio Recording",
    text: "Ensure high-quality, crisp recordings for flawless transcription.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Import from Multiple Sources",
    text: "Easily import content from audio, video, documents, and image files.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Multiple Export Features",
    text: "Easily export your content in multiple formats including audio, PDF, text, DOC, and Markdown.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Multilanguage Support",
    text: "Transcribe speech in various languages seamlessly.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Real-time Translation",
    text: "Instantly translate your recording into another language as you speak, enabling seamless multilingual communication.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Editable Transcripts",
    text: "Precisely adjust and edit your transcript down to the second, ensuring every detail is captured and corrected accurately.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Image-to-Text Conversion",
    text: "Convert images into editable text effortlessly.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Image-to-LaTeX Conversion",
    text: "Transform complex equations and symbols from images into LaTeX code.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Cross-device Sync",
    text: "Seamlessly sync your content across all your devices for a consistent experience.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Cloud Backup",
    text: "Securely store your data in the cloud with reliable backup and instant recovery.",
    icon: "/landing/icon.svg",
  },
  {
    title: "Offline Audio Model",
    text: "Leverage an offline transcription model to process and convert audio without an internet connection.",
    icon: "/landing/icon.svg",
  },
];

const WhyYouwoAI: React.FC = () => {
  return (
    <div className="w-full bg-gray-50">
      {/* Header */}
      <header className="py-6 px-4">
        <h1 className="text-4xl font-bold text-center">Why YouwoAI?</h1>
      </header>
      {/* Main Content */}
      <main className="px-4 pb-6">
        <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center">
          {features.map((feature, index) => (
            <div
              key={index}
              className="w-56 h-56 bg-white shadow rounded-xl p-4 flex flex-col items-center justify-center text-center"
            >
              <div className="bg-cornflowerblue p-2 rounded-full mb-2 flex items-center justify-center">
                <img
                  src={feature.icon}
                  alt={`${feature.title} Icon`}
                  className="h-6 w-6"
                  loading="lazy"
                />
              </div>
              <h2 className="text-base font-semibold mb-1">{feature.title}</h2>
              <p className="text-xs text-gray-600">{feature.text}</p>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default WhyYouwoAI;
